import Log from "./logger";
import { Actions } from "./entities";

export abstract class ActionsStore {
	public abstract get(p: {
		log: Log,
	}): Promise<Actions>;
	public abstract getUpdatedDate(p: {
		log: Log,
	}): Promise<Date>;
	public abstract update(p: {
		log: Log,
		actions: Actions['actions'],
	}): Promise<Actions>;
}

export default ActionsStore;
