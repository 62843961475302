import { ReactNode, createContext, useContext, useState } from "react";
import Log from "common/logger";
import { ApiError, generateId } from "common/utils";
import { ApiResponse } from "common/entities";

const ErrorsContext = createContext<ErrorHandler | undefined>(undefined);

export type ErrorItem = ErrorHandler['items'][0];
class ErrorHandler {
	public readonly items: {
		id: string;
		messages: string[];
	}[] = [];

	constructor(p: { setRefresh?: (callback: () => void) => void }) {
		p.setRefresh = (callback) => {  // Hack so that 'this.refresh()' invokes 'setHandler()' from React's 'useState()'
			this.refresh = callback;
		}
	}

	private refresh: () => void = null!;

	public clear(): void {
var HERE = 123;//Display errors & allow clear
		this.items.length = 0;
		this.refresh();
	}

	public removeById(id: string): void {
		const items = this.items;
		const idx = items.findIndex(v => v.id === id);
		if (idx !== -1)
			items.splice(idx, 1);
		this.refresh();
	}

	public async addError(p: { log?: Log, message?: string, error?: any, response?: Response }): Promise<ErrorItem> {
		const { log, message, error } = p;
		let { response } = p;
		if (error instanceof ApiError)
			response ??= error.response;

		const item: ErrorItem = {
			id: generateId(),
			messages: [message ?? error?.message ?? 'Unknown error'],
		};

		if (response != null) {
			item.messages.push(`Server returned status ${response.status}`);
			try {
				const res = await response.json() as ApiResponse;
				if (res.error)
					item.messages.push(res.error);
				if (res.log) {
					item.messages.push(`Server-side log:`);
					item.messages.push(...res.log);
				}
			}
			catch (discard) { }
		}

		if (log != null) {
			item.messages.push(`Client-side log:`);
			item.messages.push(...log.getLines());
		}

		console.error(item.messages.join('\n'));
		if (error != null)
			console.error(error);

		this.items.push(item);
		this.refresh();
		return item;
	}
}

export const ErrorHandlerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const p: { setRefresh?: (callback: () => void) => void } = {};
	const [handler, setHandler] = useState<ErrorHandler>(new ErrorHandler(p));
	p.setRefresh!(() => setHandler(handler));  // nb: Hack required bc. compilation warning if 'userState()' is used outside this function ...
	return (
		<ErrorsContext.Provider value={handler}>
			{children}
		</ErrorsContext.Provider>
	);
};

export function useErrorHandler(): ErrorHandler {
	const context = useContext(ErrorsContext);
	if (context === undefined)
		throw new Error('useErrorHandler must be used within a ErrorHandlerProvider');
	return context;
}

export default ErrorHandler;
