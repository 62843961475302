import Log from "common/logger";
import { apiRequest, queryUrl } from "common/utils";
import * as routes from "../routes";
import { Actions, Adjustment, Publication, Reference } from "common/entities";
import PublicationsStore from "common/publicationsStore";
import ReferencesStore from "common/referencesStore";
import ActionsStore from "common/actionsStore";
import AdjustmentsStore from "common/adjustmentsStore";

class PublicationsStoreApi extends PublicationsStore {
	protected override getReferencesStore() {
		return refStore;
	}

	public override async getCurrentId(): Promise<number | null> {
		throw new Error("Not implemented: PublicationsStoreApi.getCurrentId().");
	}

	public override async setCurrentId(publicationId: number): Promise<void> {
		const body: routes.api.publication.SetCurrentRequest = { publicationId };
		await apiRequest<{}>({
			method: 'POST',
			url: routes.api.publication.setCurrent(),
			exceptionMessage: 'Unable to set current publication',
			body,
		});
	}

	public override async get({ log, publicationId, recursive }: {
		log: Log,
		publicationId: number,
		recursive?: boolean,
	}): Promise<Publication[]> {
		log = log.child('pubstore');
		const query: routes.api.publication.GetRequest = { publicationId, recursive };
		log.log('get', query);
		const result = await apiRequest<routes.api.publication.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.publication.get(), query),
			exceptionMessage: 'Error while fetching publications',
		});
		return result.result;
	}

	public override async update({ log, publication }: {
		log: Log,
		publication: Publication,
	}): Promise<Publication> {
		log = log.child('pubstore');
		const body: routes.api.publication.UpdateRequest = publication;
		log.log('update', body);
		const result = await apiRequest<routes.api.publication.UpdateResult>({
			method: 'POST',
			url: routes.api.publication.update(),
			body,
			exceptionMessage: 'Error while saving publication',
		});
		return result.result;
	}
}

class ReferencesStoreApi extends ReferencesStore {
	protected override getPublicationsStore() {
		return pubStore;
	}

	public override async get({ log, publicationId, itemNumber, prevItemNumber }: {
		log: Log,
		publicationId: number,
		itemNumber?: string,
		prevItemNumber?: string,
	}): Promise<Reference[]> {
		log = log.child('refstore');
		const query: routes.api.reference.GetRequest = { publicationId, itemNumber, prevItemNumber };
		log.log('get', query);
		const result = await apiRequest<routes.api.reference.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.reference.get(), query),
			exceptionMessage: 'Error while fetching references',
		});
		return result.result;
	}

	public override add(p: { log: Log; references: Reference[]; }): Promise<Reference[]> {
		throw new Error("Not implemented: ReferencesStoreApi.add().");
	}

	public override async update({ log, items }: {
		log: Log,
		items: {
			publicationId?: number,
			itemNumber?: string,
			reference: Partial<Reference>,
		}[],
	}): Promise<Reference[]> {
		log = log.child('refstore');
		const body: routes.api.reference.UpdateRequest = { items };
		log.log('update', body);
		const result = await apiRequest<routes.api.reference.UpdateResult>({
			method: 'POST',
			url: routes.api.reference.update(),
			body,
			exceptionMessage: 'Error while saving references',
		});
		return result.result;
	}

	public override async delete({ log, items }: {
		log: Log,
		items: {
			publicationId: number,
			itemNumber: string,
		}[],
	}): Promise<void> {
		log = log.child('refstore');
		const body: routes.api.reference.RemoveRequest = { items };
		log.log('delete', body);
		await apiRequest<routes.api.reference.RemoveResult>({
			method: 'DELETE',
			url: routes.api.reference.remove(),
			body,
			exceptionMessage: 'Error while deleting references',
		});
	}
}

class ActionsStoreApi extends ActionsStore {
	public override async get({ log }: {
		log: Log,
	}): Promise<Actions> {
		log = log.child('actstore');
		log.log('get');
		const { actions, updatedAt } = await apiRequest<routes.api.actions.GetResult>({
			method: 'GET',
			url: routes.api.actions.get(),
			exceptionMessage: 'Error while fetching actions',
		});
		return {
			actions,
			updatedAt: new Date(updatedAt),  // nb: dates come as ISO strings from server
		};
	}

	public override getUpdatedDate({ log }: {
		log: Log,
	}): Promise<Date> {
		throw new Error("Not implemented: ActionsStoreApi.getUpdatedDate().");
	}

	public override async update({ log, actions }: {
		log: Log,
		actions: Actions["actions"],
	}): Promise<Actions> {
		log = log.child('actstore');
		log.log('post', { actions });
		const body: routes.api.actions.UpdateRequest = { actions };
		return await apiRequest<routes.api.actions.UpdateResult>({
			method: 'POST',
			url: routes.api.actions.get(),
			body,
			exceptionMessage: 'Error while updating actions',
		});
	}
}

class AdjustmentsStoreApi extends AdjustmentsStore {
	public override async get({ log, itemNumber }: {
		log: Log,
		itemNumber?: string,
	}): Promise<Adjustment[]> {
		log = log.child('adjstore');
		const body: routes.api.adjustment.GetRequest = { itemNumber };
		log.log('get', body);
		const result = await apiRequest<routes.api.adjustment.GetResult>({
			method: 'GET',
			url: queryUrl(routes.api.adjustment.get(), body),
			exceptionMessage: 'Error while fetching adjustments',
		});
		return result.result;
	}

	public override async save({ log, itemNumber, adjustment }: {
		log: Log,
		itemNumber: string,
		adjustment: Adjustment,
	}): Promise<Adjustment> {
		log = log.child('adjstore');
		const body: routes.api.adjustment.SaveRequest = { itemNumber, adjustment };
		log.log('save', body);
		const result = await apiRequest<routes.api.adjustment.SaveResult>({
			method: 'POST',
			url: routes.api.adjustment.saveSingle(),
			body,
			exceptionMessage: 'Error while saving adjustment',
		});
		return result.result;
	}

	public override async delete({ log, itemNumber }: {
		log: Log,
		itemNumber: string,
	}): Promise<Adjustment | null> {
		log = log.child('adjstore');
		const body: routes.api.adjustment.DeleteRequest = { itemNumber };
		log.log('delete', body);
		const result = await apiRequest<routes.api.adjustment.DeleteResult>({
			method: 'DELETE',
			url: routes.api.adjustment.deleteSingle(),
			body,
			exceptionMessage: 'Error while deleting adjustment',
		});
		return result.result;
	}
}

const pubStore = new PublicationsStoreApi();
const refStore = new ReferencesStoreApi();
const actStore = new ActionsStoreApi();
const adjStore = new AdjustmentsStoreApi();

export function usePublicationsSource() {
	return pubStore;
}

export function useReferencesSource() {
	return refStore;
}

export function useActionsSource() {
	return actStore;
}

export function useAdjustmentsSource() {
	return adjStore;
}
