import { useEffect, useMemo, useRef, useState } from 'react';
import CodeMirror from '@uiw/react-codemirror';

import * as entities from 'common/entities';
import * as chromeExtension from 'common/chromeExtension';

import * as icons from '../media/icons';
import { useLayout } from './Layout';
import Translatable from '../components/Translatable';
import { useActions } from '../providers/ActionsProvider';

type ActionsList = entities.Actions['actions'];

function Actions() {
	const { isDarkMode, setHeaderPlaceholder: setHeaderButtons } = useLayout();
	const { actions: initialActions, status: actionsStatus, update: updateActions } = useActions();
	const [text, setText] = useState(() => getListText(initialActions));

	const isBusy = (actionsStatus !== 'ok');
	const isModified = useMemo(() => {
		const oldText = getListText(initialActions);
		const newText = getListText(getTextList(text));
		return oldText !== newText;
	}, [initialActions, text]);

	// reset text whenever 'initialActions' changes
	useEffect(() => {
		setText(getListText(initialActions));
	}, [initialActions]);

	// wait for messages from Chrome extension
	useEffect(() => {
		function handleMessage(evt: MessageEvent<chromeExtension.AddinMessage>) {
			const message = evt.data;
			if (message.type !== chromeExtension.addinMessageType)
				return;

			const txt = message.actions
				.sort((a, b) => parseInt(a) - parseInt(b))
				.join('\n');
			setText(txt);
		};
		window.addEventListener('message', handleMessage);

		return () => window.removeEventListener('message', handleMessage);
	}, []);

	// place action buttons in the layout's header
	const headerButtonsProps = useRef({
		handleSave: () => { },
		handleCancel: () => { },
	}).current;
	useEffect(() => {
		setHeaderButtons(
			ActionButtons({
				isBusy,
				isModified,
				...headerButtonsProps,
			})
		);
		// restore layout's header at umount
		return () => setHeaderButtons(null);
	}, [text, isBusy, isModified, setHeaderButtons, headerButtonsProps]);

	headerButtonsProps.handleCancel = () => {
		setText(getListText(initialActions ?? []));
	}
	headerButtonsProps.handleSave = () => {
		const actions = getTextList(text);
		updateActions({ actions });
	}

	if (isBusy)
		return <icons.LoadingSpinnerPage />;

	return <>
		<CodeMirror
			value={text}
			theme={isDarkMode ? "dark" : "light"}
			onChange={(val) => {
				setText(val)
			}}
		/>
	</>
}

function ActionButtons({ isBusy, isModified, handleSave, handleCancel }: {
	isBusy: boolean,
	isModified: boolean,
	handleSave: () => void,
	handleCancel: () => void,
}) {
	const showSave = isModified;
	const showCancel = isModified;

	if (isBusy)
		return <icons.LoadingSpinnerButton />

	if ((!showSave) && (!showCancel))
		// show nothing
		return null;

	return <span>
		{showSave &&
			<button type="submit" className="btn btn-primary ms-1" onClick={handleSave}>
				<Translatable>{{
					fr: `Enregistrer`,
					nl: `Opslaan`,
				}}</Translatable>
			</button>
		}

		{showCancel &&
			<button type="submit" className="btn btn-secondary ms-1" onClick={handleCancel}>
				<Translatable>{{
					fr: `Annuler`,
					nl: `Annuleren`,
				}}</Translatable>
			</button>
		}
	</span>
}

function getListText(actions: ActionsList) {
	return actions
		.map((v) => v.itemNumber)
		.sort((a, b) => parseInt(a) - parseInt(b))
		.join('\n');
}

function getTextList(text: string): ActionsList {
	return text
		.split('\n')
		.map(v => v.trim())
		.filter(v => v !== '')
		.sort((a, b) => parseInt(a) - parseInt(b))
		.map((itemNumber) => ({ itemNumber }));
}

export default Actions;
