import { useEffect, useRef, useState } from "react";

import * as entities from "common/entities";
import { useSettings } from "./SettingsContext";
import { shieldException } from "../utils";
import { useActionsSource } from "./DataSources";

type ActionsList = entities.Actions['actions'];

export function useActions() {
	const { settings, newLog, refreshSettings } = useSettings();
	const actionsSource = useActionsSource();
	const [oldUpdatedAt, setOldUpdatedAt] = useState(() => new Date(0));
	const [actions, setActions] = useState<ActionsList>([]);
	const [status, setStatus] = useState<'ok' | 'fetch' | 'update'>('ok');

	useEffect(() => {
		shieldException(newLog('getactions'),
			async (log) => {
				if (oldUpdatedAt.getTime() === settings.actionsUpdatedAt.getTime())
					return;

				log.log('actionsUpdatedAt changed => fetch', { old: oldUpdatedAt.toISOString(), new: settings.actionsUpdatedAt.toISOString() });
				setStatus('fetch');
				const { actions, updatedAt } = await actionsSource.get({ log });
				setActions(actions);
				setOldUpdatedAt(updatedAt);
			}, () => {
				setStatus('ok');
			});
	}, [newLog, settings, actionsSource, oldUpdatedAt]);

	const { current: update } = useRef(async function ({ actions }: { actions: ActionsList }) {
		await shieldException(newLog('actions'),
			async (log) => {
				log.log('Update actions')
				setStatus('update');
				await actionsSource.update({ log, actions });
				log.log('Trigger settings refresh');
				await refreshSettings();
			}, () => {
				setStatus('ok');
			});
	});

	return {
		status,
		actions,
		update,
	};
}
