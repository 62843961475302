import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';

import Log from 'common/logger';
import { dateString, dateValue, shieldException, throwError, useRefreshOnChange } from '../utils';
import { Language } from 'common/language';
import * as rules from 'common/itemRules';
import * as fltr from 'common/itemsFilters';

import { useSettings } from '../providers/SettingsContext';
import { useLanguage } from '../providers/LanguageContext';
import { usePublicationsCache } from '../providers/PublicationsCacheContext';
import { useActionsSource, useAdjustmentsSource } from '../providers/DataSources';
import { useDialog } from '../providers/Dialogs';

import * as icons from '../media/icons';
import { DatePicker } from '../components/inputs';
import Tooltip from '../components/Tooltip';
import Translatable from '../components/Translatable';
import ItemsFilter from '../components/ItemsFilter';
import * as refTable from '../components/ReferencesTable';

/** main content */
function Adjustments() {
	const navigate = useNavigate();
	const location = useLocation();

	const { settings, newLog } = useSettings();
	const { language } = useLanguage();
	const pubsCache = usePublicationsCache();
	const refreshDependency = useRefreshOnChange(pubsCache.changed);  // nb: trigger re-render whenever the publications are updated
	const actSrc = useActionsSource();
	const adjSrc = useAdjustmentsSource();

	const [pageData, setPageData] = useState<null | 'error' | { states: rules.AdjustmentState[] }>(null);
	const [filter, setFilter] = useState<null | fltr.AdjustmentsFilter>(null);

	// load page data (& reload whenever the publication changes)
	useEffect(() => {
		shieldException(newLog('loadPageData'), async (log) => {
			const publicationId = settings.currentPublication?.id ?? throwError('Current publication not available');
			return await rules.AdjustmentState.fetch(log, publicationId, pubsCache, actSrc, adjSrc);
		}).then(v => setPageData(v));
	}, [settings, pubsCache, adjSrc, refreshDependency, newLog]);
	const currentPublication = settings.currentPublication!;

	// apply filter & watch for url changes
	useEffect(() => {
		if ((pageData == null) || (pageData === 'error')) {
			// [Re]set state
			setFilter(null);
			return;
		}

		if (filter == null)
			// build from scratch
			setFilter(createFilter(pageData.states, language, location.search, navigate));
		else
			// only recalculate using new URL
			filter.fromUrlParms({ searchString: location.search });
	}, [pageData, filter, language, location.search, navigate]);

	if (pageData === 'error')
		return <icons.LoadingError />;
	if ((pageData == null) || (filter == null))
		return <icons.LoadingSpinnerPage />;

	const onLineUpdated = async (log: Log, origState: rules.AdjustmentState, newState: rules.AdjustmentState) => {
		const idx = pageData.states.findIndex(v => v.item.itemNumber === origState.item.itemNumber);
		if (idx < 0)
			throwError(`Unable to find index of line '${origState?.item?.itemNumber}'`, log);

		// replace state & trigger refresh
		pageData.states[idx] = newState;
		setPageData({ ...pageData });
		setFilter(createFilter(pageData.states, language, location.search, navigate));
	}

	return <Container
		fluid
		className="mt-3"
	>
		<Form.Group as={Row}>
			<Form.Label column className="col">
				<Translatable>{{
					fr: 'Dernière mise à jour des prix le ',
					nl: 'Laatste prijsupdate op ',
				}}</Translatable>
				{dateString({ d: currentPublication.date, withTime: false })}
			</Form.Label>
		</Form.Group>

		<hr />

		<ItemsFilter
			filter={filter}
		/>

		<AjustmentsTable
			filter={filter}
			language={language}
			onLineUpdated={onLineUpdated}
		/>
	</Container>
}

function AjustmentsTable({ filter, language, onLineUpdated }: {
	filter: fltr.AdjustmentsFilter,
	language: Language,
	onLineUpdated: (log: Log, origState: rules.AdjustmentState, newState: rules.AdjustmentState) => Promise<void>,
}) {
	return <>
		<refTable.Paging filter={filter} />

		<Table striped hover className="table-sm">
			<thead>
				<tr>
					<refTable.CellHeader type="itemNumber" style={{ width: '1%' }} />
					<refTable.CellHeader type="name" />
					<refTable.CellHeader type="price" style={{ width: '1%' }} />
					<th colSpan={2} style={{ width: '1%' }}>
						<Translatable>{{
							'fr': 'Ajusté',
							'nl': <Tooltip content='Gecorrigeerde'>
								Gec.
							</Tooltip>,
						}}</Translatable>
					</th>
					<refTable.CellHeader type="group" />
				</tr>
				<tr>
					<refTable.CellFilter type="itemNumber" filter={filter} />
					<refTable.CellFilter type="name" filter={filter} />
					<th colSpan={3} />
					<refTable.CellFilter type="group" filter={filter} />
				</tr>
			</thead>
			<tbody>
				{filter.filteredRules.map((line) =>
					<AdjustmentLine key={line.item.itemNumber}
						state={line}
						language={language}
						onLineUpdated={onLineUpdated}
					/>
				)}
			</tbody>
		</Table>

		<refTable.Paging filter={filter} />
	</>
}

function AdjustmentLine({ state, language, onLineUpdated }: {
	state: rules.AdjustmentState,
	language: Language,
	onLineUpdated: (log: Log, origState: rules.AdjustmentState, newState: rules.AdjustmentState) => Promise<void>,
}) {
	const adjSrc = useAdjustmentsSource();
	const { newLog } = useSettings();
	const { confirm } = useDialog();
	const adjPriceRef = useRef<HTMLInputElement>(null);
	const [isBusy, setIsBusy] = useState(false);
	const [isAdjPriceInFocus, setIsAdjPriceInFocus] = useState<boolean>(false);
	const [adjPriceEdited, setAdjPriceEdited] = useState<number | null>(null);
	const [expirationEdited, setExpirationEdited] = useState<string | null>(null);
	const [detailsExpanded, setDetailsExpanded] = useState<boolean>(false);

	const hasAdjustment = (state.adjustment != null);

	const validateForPrice = state.validatedForReference?.price ?? 0;
	const initialPrice = state.adjustment?.adjustedPrice ?? state.item.price;
	const isPriceEdited = (adjPriceEdited != null);
	const isExpirationEdited = (expirationEdited != null);

	const isEdited = (isPriceEdited || isExpirationEdited);

	const detailsState =
		isEdited ? 'edited'
			: hasAdjustment ? (detailsExpanded ? 'expanded' : 'collapsed')
				: 'none';

	const adjPriceDisplayed = isPriceEdited ? adjPriceEdited
		: hasAdjustment ? state.adjustment.adjustedPrice
			: isAdjPriceInFocus ? state.item.price
				: '';

	const expirationDisplayed = dateValue({
		str: isExpirationEdited ? expirationEdited
			: hasAdjustment ? state.adjustment.validUntil
				: '1970-01-01'  // nb: should never be shown at this stage
	})!;

	function handleAdjPriceFocus(focusing: boolean) {
		setIsAdjPriceInFocus(focusing);
		if (focusing)
			// select all text (but needs to be delayed, bc. at this time, the textbox is likely to be empty)
			setTimeout(() => {
				adjPriceRef.current?.select();
			}, 0);
	}

	function handleAdjPriceChange(strValue: string) {
		if (strValue === '') {
			// user explicitely cleared the textbox => handle like a 'cancel' button click
			resetEdited();
			return;
		}

		let value = parseFloat(strValue);
		if (isNaN(value))
			// not a valid number => ignore change => will keep previous value
			return;

		value = parseFloat(value.toFixed(2));  // truncate to 2 decimals
		if (value === initialPrice) {
			// reset to "untouched"
			resetEdited();
			return;
		}

		setAdjPriceEdited(value);
		if (!isExpirationEdited)
			// no expiration date specified => use default
			setExpirationEdited(rules.getExpirationDateDefault());
	}

	function handleAdjPriceKeyDown(key: string) {
		switch (key) {
			case 'Enter':
				if (isEdited)
					/*await*/saveAdjustment();
				break;
			case 'Escape':
				resetEdited();
				break;
		}
	}

	function handleExpirationChange(d: Date) {
		const str = dateString({ d, withTime: false });
		if (hasAdjustment && (state.adjustment!.validUntil === str)) {
			// reset edited date
			setExpirationEdited(null);
			return;
		}
		setExpirationEdited(str);
	}

	function resetEdited() {
		setAdjPriceEdited(null);
		setExpirationEdited(null);
	}

	async function saveAdjustment() {
		const log = newLog('saveAdjustment');
		setIsBusy(true);
		try {
			const origState = state;
			const curRef = state.item;
			const curItemNmber = curRef.itemNumber;
			const origAdj = origState.adjustment;
			const origItemNumber = origAdj?.itemNumber ?? curItemNmber;  // "update original" ?? "create current"

			// save adjustment
			const newAdj = await adjSrc.save({
				log,
				itemNumber: origItemNumber,
				adjustment: {
					itemNumber: curItemNmber,
					validatedForPublicationId: curRef.publicationId,
					adjustedPrice: adjPriceEdited ?? origAdj?.adjustedPrice ?? throwError(`Logic error: 'adjustedPrice' is supposed to be available here`),
					validUntil: expirationEdited ?? origAdj?.validUntil ?? throwError(`Logic error: 'validUntil' is supposed to be available here`),
				},
			});

			// reset edition if any
			resetEdited();
			setDetailsExpanded(false);

			// recreate AdjustmentState & warn parent
			const newState = new rules.AdjustmentState(curRef, newAdj, curRef, origState.isInAction);
			await onLineUpdated(log, origState, newState);
		} catch (ex) {
			log.error(`Error while saving adjustment`);
			log.exception(ex);
		} finally {
			setIsBusy(false);
		}
	}

	const deleteAdjustment = async (confirmed = false) => {
		if (!confirmed) {
			if (await confirm({
				message: {
					fr: 'Supprimer cet ajustement?',
					nl: 'Deze aanpassing verwijderen?',
				},
			}))
				deleteAdjustment(true);
			return;
		}

		const log = newLog('deleteAdjustment');
		setIsBusy(true);
		try {
			const curRef = state.item;
			const origState = state;
			const origAdj = origState.adjustment ?? throwError('Logic error: adjustment is supposed to be set here', log)

			// delete adjustment
			const deletedAdj = await adjSrc.delete({ log, itemNumber: origAdj.itemNumber });
			if (deletedAdj == null)
				throwError(`Could not delete adjustment '${origAdj.itemNumber}'`, log);

			// reset edition if any
			resetEdited();

			// recreate AdjustmentState & warn parent
			const newState = new rules.AdjustmentState(curRef, null, null, origState.isInAction);
			await onLineUpdated(log, origState, newState);
		} catch (ex) {
			log.error(`Error while deleting adjustment`);
			log.exception(ex);
		} finally {
			setIsBusy(false);
		}
	}

	if (isBusy)
		return <tr>
			<td colSpan={6}>
				<icons.LoadingSpinnerButton />
			</td>
		</tr>

	return <>
		<tr className={state.hasErrors() ? 'table-danger' : ''}>
			<refTable.CellContent type="itemNumber" state={state} language={language} />
			<refTable.CellContent type="name" state={state} language={language} />
			<refTable.CellContent type="price" state={state} language={language} />
			<td>{/*adjustedPrice*/}
				<input type="number" ref={adjPriceRef}
					className="form-control  py-0"
					style={{
						width: '5em',
						textAlign: 'right',
					}}
					step={.01}
					value={adjPriceDisplayed}
					onFocus={() => handleAdjPriceFocus(true)}
					onBlur={() => handleAdjPriceFocus(false)}
					onChange={(evt) => handleAdjPriceChange(evt.target.value)}
					onKeyDown={(evt) => handleAdjPriceKeyDown(evt.key)}
				/>
			</td>
			<td>{/*icons*/}
				{{
					'edited': <icons.ItemEdited />,
					'collapsed': <span onClick={() => setDetailsExpanded(true)} style={{ cursor: 'pointer' }}><icons.ItemDetailsExpand /></span>,
					'expanded': <span onClick={() => setDetailsExpanded(false)} style={{ cursor: 'pointer' }}><icons.ItemDetailsCollapse /></span>,
					'none': <></>,
				}[detailsState]}
			</td>

			{((detailsState === 'expanded') || (detailsState === 'edited')) ? <>
				{/*when showing adjustment details*/}
				<td>

					{(hasAdjustment && (state.item.price !== validateForPrice)) && <>
						<Tooltip
							style={{ whiteSpace: 'nowrap' }}
							content={
								<Translatable>{{
									'fr': 'Prix lors de la dernière validation de l\'ajustement',
									'nl': 'Prijs bij de laatste validatie van de aanpassing',
								}}</Translatable>}>
							<Translatable>{{
								'fr': 'Originel:',
								'nl': 'Oorspronkelijk:',
							}}</Translatable>
							&nbsp;
							<input type="text" readOnly tabIndex={-1} value={validateForPrice}
								className="form-control py-0"
								style={{
									display: 'inline',
									width: '5em',
									textAlign: 'right',
								}}
							/>
						</Tooltip>
						&nbsp;
					</>}

					<span style={{ whiteSpace: 'nowrap' }}>
						<Translatable>{{
							'fr': 'Expiration:',
							'nl': 'Vervaldatum:',
						}}</Translatable>
						&nbsp;
						<DatePicker
							mode='adjustment'
							innerClassName="form-control py-0"
							style={{
								display: 'inline-block',
								width: '7em',
								whiteSpace: 'normal',
							}}
							allowNull={false}
							value={expirationDisplayed}
							onChange={handleExpirationChange}
						/>
					</span>
					&nbsp;

					<span style={{ whiteSpace: 'nowrap' }}>
						{<>
							<Tooltip content={
								<Translatable>{{
									'fr': 'Valider l\'ajustement',
									'nl': 'De aanpassing goedkeuren',
								}}</Translatable>}>
								<span onClick={() => /*await*/saveAdjustment()} style={{ cursor: 'pointer', color: 'green' }}>
									<icons.ItemSave size="1.3em" />
								</span>
							</Tooltip>
							&nbsp;
						</>}

						{{
							'edited': <>
								<Tooltip content={
									<Translatable>{{
										'fr': 'Annuler la modification',
										'nl': 'Annuleer wijziging',
									}}</Translatable>}>
									<span onClick={() => resetEdited()}>
										<icons.ItemCancel size="1.3em" style={{ cursor: 'pointer', color: 'red' }} />
									</span>
								</Tooltip>
								&nbsp;
							</>,
							'expanded': <>
								<Tooltip content={
									<Translatable>{{
										'fr': 'Supprimer l\'ajustement',
										'nl': 'Verwijder de aanpassing',
									}}</Translatable>}>
									<span onClick={() =>/*await*/deleteAdjustment()}>
										<icons.ItemDelete size="1.3em" style={{ cursor: 'pointer', color: 'red' }} />
									</span>
								</Tooltip>
								&nbsp;
							</>,
						}[detailsState]}
					</span>

				</td>
			</> : <>
				{/*when showing reference item*/}
				<refTable.CellContent type="group" state={state} language={language} />
			</>}
		</tr>
	</>
}

/** create an 'AdjustmentsFilter' instance */
function createFilter(states: rules.AdjustmentState[], language: Language, locationSearch: string, navigate: ReturnType<typeof useNavigate>) {
	const filter: fltr.AdjustmentsFilter = new fltr.AdjustmentsFilter(
		language,
		states,
		() => navigate(filter.toUrlParms(), { replace: true })  // whenever the filter changes, update URL with the new parameters
	);
	filter.fromUrlParms({ searchString: locationSearch });
	return filter;
}

export default Adjustments;
