import * as api from 'common/routes';
api.setFrontend();
export * as api from 'common/routes';

export const home = `/`;
export const publication = (id: number | ':id') => `/publication/${id}`;
export const publication_upload = `/publication/upload`;
export const reference = (pubId: number | ':pubId', itemNumber: string | ':itemNumber') => `/reference/${pubId}/${itemNumber === ':itemNumber' ? itemNumber : encodeURIComponent(itemNumber)}`;
export const actions = `/actions`;
export const adjustments = `/adjustments`;
