import { ForwardedRef, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Container from "react-bootstrap/Container";
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import Log from 'common/logger';
import { dateString, dateValue, deepClone, ensureIntOrThrow, generateId, shieldException, throwError, useRefreshOnChange } from '../utils';
import * as routes from '../routes';
import * as entities from 'common/entities';
import { TranslatedString } from 'common/language';
import * as fltr from 'common/itemsFilters';
import { ReferenceState } from 'common/itemRules';

import { useSettings } from '../providers/SettingsContext';
import { useLanguage } from '../providers/LanguageContext';
import { useDialog } from '../providers/Dialogs';
import { usePublicationsSource } from '../providers/DataSources';
import { usePublicationsCache } from '../providers/PublicationsCacheContext';

import { useLayout } from './Layout';
import * as icons from '../media/icons';
import { DatePicker } from '../components/inputs';
import Translatable from '../components/Translatable';
import ItemsFilter from '../components/ItemsFilter';
import ReferencesTable, { SelectableReferenceState } from '../components/ReferencesTable';

/** main content */
function Publication() {
	const navigate = useNavigate();
	const location = useLocation();
	const pageReady = useRef(false);

	const { settings, newLog, refreshSettings, hasAccess } = useSettings();
	const canModify = hasAccess('publication_manage');
	const { language, translate } = useLanguage();
	const { confirm } = useDialog();
	const { setHeaderPlaceholder: setHeaderButtons } = useLayout();

	const log = newLog('publication');
	const { id: strPublicationId } = useParams<{ id: string }>();
	const publicationId = ensureIntOrThrow({ n: strPublicationId, log, errorMsg: `Invalid publication id specified` });
	const pubSrc = usePublicationsSource();
	const pubsCache = usePublicationsCache();

	const [pageData, setPageData] = useState<null | 'error' | { publication: entities.Publication, references: entities.Reference[] }>(() => {
		const { publication, references } = pubsCache.tryGet({ log, publicationId });
		if ((publication !== null) && (references !== null))
			return { publication, references };
		return null;
	});
	const hasPageData = (pageData !== null) && (pageData !== 'error');
	const [filter,] = useState(() => {
		const f = new fltr.ReferencesFilter(language);
		if (hasPageData)
			f.allItems = (!hasPageData) ? [] : pageData.references.map((reference) =>
				canModify
					? new SelectableReferenceState(reference, f, (line) => {
						line.selected = !line.selected;
						filter.computedRulesChanged.trigger();
					})
					: new ReferenceState(reference)
			);
		return f;
	});
	const [selectedCount, setSelectedCount] = useState(0);

	const pageDataDependency = useRefreshOnChange(pubsCache.tryGet({ log, publicationId }).changed)  //  trigger rerender whenever the cache for this publication is updated
	const filterDependency = useRefreshOnChange(filter.computedRulesChanged);  // trigger rerender whenever the filter has changed

	const headerRef = useRef({ update: (pub: entities.Publication) => { }, reset: () => { } });
	const [headerHasChanges, setHeaderHasChanges] = useState(false);
	const [isBusy, setIsBusy] = useState(false);
	const isCurrent = (!hasPageData) ? true : (settings.currentPublication?.id === pageData.publication.publicationId);

	// load page data (& reload whenever `pubId` and `itemNumber` parameters changes)
	useEffect(() => {
		shieldException(newLog('loadPageData'), async (log) => {
			pageReady.current = false;
			setPageData(null);

			const { changed, publication, references } = pubsCache.tryGet({ log, publicationId, fetchIfUnavailable: true });
			if ((publication === null) || (references === null))
				return null;  // nb: will display the "loading spinning icon", fetch data in the background then update `pageDataDependency`
			else
				return { changed, publication, references };
		}).then(v => setPageData(v));
	}, [publicationId, pubsCache, pageDataDependency, newLog]);

	// fill filter object whenever pageData has changed
	useEffect(() => {
		if (!hasPageData)
			return;
		filter.allItems = pageData.references.map((reference) =>
			canModify
				? new SelectableReferenceState(reference, filter, (line) => {
					line.selected = !line.selected;
					filter.computedRulesChanged.trigger();
				})
				: new ReferenceState(reference)
		);
	}, [canModify, hasPageData, pageData, pageDataDependency, filter]);

	// update filter whenever URL or language changes
	useEffect(() => {
		if (!hasPageData)
			// data still loading
			return;

		filter.language = language;
		filter.fromUrlParms({ searchString: location.search, clearFirst: true });
	}, [filter, hasPageData, language, location.search]);

	// update URL whenever filter changes
	useEffect(() => {
		if (!hasPageData)
			// data still loading
			return;
		navigate(filter.toUrlParms(), { replace: true });
	}, [filter, filterDependency, hasPageData, navigate]);

	// recount selected lines
	useEffect(() => {
		if (!canModify)
			// cannot select anything => this count is not used
			return;
		const cnt = filter.allItems.reduce((count, v) => {
			return (v as SelectableReferenceState).selected ? count + 1 : count;
		}, 0);
		setSelectedCount(cnt);

	}, [canModify, filter, filterDependency]);

	// place action buttons in the layout's header
	const [headerButtonsProps,] = useState<Omit<Omit<Omit<Parameters<typeof PublicationButtons>[0], 'isBusy'>, 'selectedCount'>, 'filter'>>(() => ({
		handleCancel: null,
		handleSave: null,
		handleSetAsCurrent: null,
		handleUnselect: null,
		handleDeleteSelected: null,
	}));
	useEffect(() => {

var HERE = 123;//this should be 'useHeaderButtons()' with dependencies passed as parameters & {JSX.Element|null} be returned by callback

		setHeaderButtons(
			PublicationButtons({  // nb: returns 'null' when nothing to display => will restore the layout's default header
				isBusy,
				selectedCount,
				...headerButtonsProps,
			})
		);

		// restore layout's header at umount
		return () => setHeaderButtons(null);
	}, [canModify, isCurrent, selectedCount, headerHasChanges, isBusy, headerButtonsProps, setHeaderButtons]);

	if (pageData === 'error')
		return <icons.LoadingError />;
	if (pageData == null)
		return <icons.LoadingSpinnerPage />;

	const publication = pageData.publication;

	headerButtonsProps.handleCancel = null;
	headerButtonsProps.handleSave = null;
	headerButtonsProps.handleSetAsCurrent = null;
	headerButtonsProps.handleUnselect = null;
	headerButtonsProps.handleDeleteSelected = null;
	if (canModify && headerHasChanges) {
		headerButtonsProps.handleCancel = function () {
			headerRef.current.reset();
		}
		headerButtonsProps.handleSave = function () {
			shieldException(log.child('handleSave'), async (log) => {
				setIsBusy(true);

				const newPub = deepClone(publication);
				headerRef.current.update(newPub);

				await pubsCache.update({ log, publications: [newPub] });
			}, () => setIsBusy(false));
		}
	}
	if (canModify && (!isCurrent)) {
		headerButtonsProps.handleSetAsCurrent = function () {
			shieldException(log.child('setcurrent'), async (log) => {
				setIsBusy(true);

				const newPub = deepClone(publication);
				headerRef.current.update(newPub);

				const confirmed = await validateSetAscurrent({
					log: log.child('validate'),
					settings,
					confirm,
					translate,
					filter,
					pubSrc,
					newPub,
				});
				if (!confirmed)
					return;

				await pubSrc.setCurrentId(publication.publicationId);
				await refreshSettings();
			}, () => setIsBusy(false));
		}
	}
	if (canModify && (selectedCount > 0)) {
		headerButtonsProps.handleUnselect = function () {
			const all = filter.allItems as SelectableReferenceState[];
			all.forEach(v => v.selected = false);
			filter.computedRulesChanged.trigger();
		};

		headerButtonsProps.handleDeleteSelected = function () {
			shieldException(log.child('delete'), async (log) => {
				setIsBusy(true);

				const all = filter.allItems as SelectableReferenceState[];
				const deleteRefs = all.filter(v => v.selected).map((v) => ({
					publicationId: v.item.publicationId,
					itemNumber: v.item.itemNumber,
				}));

				const confirmed = await confirm({
					message: {
						fr: `Êtes-vous sûr de vouloir supprimer les ${deleteRefs.length} enregistrements sélectionnés ?`,
						nl: `Weet u zeker dat u de ${deleteRefs.length} geselecteerde records wilt verwijderen?`,
					},
				});
				if (!confirmed)
					return;

				await pubsCache.update({ log, deleteRefs });
			}, () => setIsBusy(false));
		}
	}

	return <Container
		fluid
		className="mt-3"
	>
		<PublicationHeader
			ref={headerRef}
			filter={filter}
			readOnly={!canModify}
			publication={publication}
			setHasChanges={setHeaderHasChanges}
		/>

		<hr />

		<ItemsFilter
			filter={filter}
		/>

		<ReferencesTable
			filter={filter}
		/>
	</Container>
}// main content

/** header (ie. publication's informations) */
const PublicationHeader = forwardRef(({ filter, publication, readOnly, setHasChanges }: {
	filter: fltr.FilterBase,
	publication: Readonly<entities.Publication>,
	readOnly: boolean,
	setHasChanges: (v: boolean) => void,
}, ref: ForwardedRef<{
	reset: () => void,
	update: (p: entities.Publication) => void,
}>) => {
	const [pubDate, setPubDate] = useState(new Date());

	// reset all changes if publicationId changes (ie. displaying another publication)
	const reset = useCallback(() => {
		setPubDate(dateValue({ str: publication.date })!);
	}, [publication.date]);
	useEffect(reset, [publication.publicationId, reset]);

	useImperativeHandle(ref, () => ({
		reset,
		update: (publication) => {
			publication.date = dateString({ d: pubDate, withTime: false });
		},
	}));

	// warn parent of changes if any
	useEffect(() => {
		setHasChanges(publication.date !== dateString({ d: pubDate, withTime: false }));
	}, [pubDate, setHasChanges, publication]);

	return <>
		{/* id */}
		<Form.Group as={Row}>
			<Form.Label column className="col-2">
				<Translatable>{{
					fr: 'ID:',
					nl: 'ID:',
				}}</Translatable>
			</Form.Label>
			<Col className="col-4">
				<Form.Control
					plaintext
					readOnly
					value={publication.publicationId}
				/>
			</Col>

			{/* link to previous publication */}
			{(publication.prevId != null) && <>
				<Form.Label column className="col-2 text-end">
					<Translatable>{{
						fr: 'Précédent:',
						nl: 'Vorige:',
					}}</Translatable>
				</Form.Label>
				<Col className="col-4">
					<Link
						className="form-control-plaintext"
						to={routes.publication(publication.prevId) + filter.toUrlParms()}
					>
						<icons.PageLinkOther />
						{` ${publication.prevId}`}
					</Link>
				</Col>
			</>}
		</Form.Group>

		{/* date */}
		<Form.Group as={Row}>
			<Form.Label column>
				<Translatable>{{
					fr: `Date:`,
					nl: `Datum:`,
				}}</Translatable>
			</Form.Label>
			<DatePicker
				outerClassName="col-10"
				innerClassName={readOnly ? "form-control-plaintext" : "form-control"}
				value={pubDate}
				allowNull={false}
				onChange={readOnly ? undefined : setPubDate}
			/>
		</Form.Group>
	</>
});// header (ie. publication's informations)

/** publication's action buttons (ie. placed in the layout's header) */
function PublicationButtons({ isBusy, selectedCount, handleCancel, handleSave, handleSetAsCurrent, handleUnselect, handleDeleteSelected }: {
	isBusy: boolean,
	selectedCount: number,
	handleCancel: null | (() => void),
	handleSave: null | (() => void),
	handleSetAsCurrent: null | (() => void),
	handleUnselect: null | (() => void),
	handleDeleteSelected: null | (() => void),
}) {
	const showSave = handleSave !== null;
	const showCancel = handleCancel !== null
	const showSetAsCurrent = handleSetAsCurrent !== null;
	const showUnselect = handleUnselect !== null;
	const showDeleteSelected = handleDeleteSelected !== null;

	if (isBusy)
		return <icons.LoadingSpinnerButton />

	if (!(showSave || showCancel || showSetAsCurrent || showDeleteSelected))
		// no button to display ; ie. restore layout's default header
		return null;

	return <span>
		{showSave && <button type="submit"
			className="btn btn-primary ms-1"
			onClick={handleSave}>
			<Translatable>{{
				fr: `Enregistrer`,
				nl: `Opslaan`,
			}}</Translatable>
		</button>}

		{showCancel && <button type="submit"
			className="btn btn-secondary ms-1"
			onClick={handleCancel}>
			<Translatable>{{
				fr: `Annuler`,
				nl: `Annuleren`,
			}}</Translatable>
		</button>}

		{showSetAsCurrent && <button type="submit"
			className="btn btn-primary ms-1"
			onClick={handleSetAsCurrent}>
			<Translatable>{{
				fr: 'Publier comme actuel',
				nl: 'Publiceer als actueel',
			}}</Translatable>
		</button>}

		{showUnselect && <button type="submit"
			className="btn btn-secondary ms-1"
			onClick={handleUnselect}>
			<Translatable>{{
				fr: `Désélectionner (${selectedCount})`,
				nl: `Deselecteren (${selectedCount})`,
			}}</Translatable>
		</button>}

		{showDeleteSelected && <button type="submit"
			className="btn btn-primary ms-1"
			onClick={handleDeleteSelected}>
			<Translatable>{{
				fr: `Supprimer (${selectedCount})`,
				nl: `verwijder (${selectedCount})`,
			}}</Translatable>
		</button>}
	</span>
}  // publication's action buttons (ie. placed in the layout's header)

async function validateSetAscurrent({ log, settings, confirm, translate, filter, pubSrc, newPub }: {
	log: Log,
	settings: ReturnType<typeof useSettings>['settings'],
	confirm: ReturnType<typeof useDialog>['confirm'],
	translate: ReturnType<typeof useLanguage>['translate'],
	filter: fltr.ReferencesFilter,
	pubSrc: ReturnType<typeof usePublicationsSource>,
	newPub: entities.Publication,
}) {
	const prevPubId = newPub.prevId;
	const currPubId = settings.currentPublication?.id;
	const warnings = [] as TranslatedString[];

	if (prevPubId !== currPubId)
		warnings.push({
			fr: 'La publication définie comme précédente n\'est pas l\'actuelle',
			nl: 'De publicatie die als voorheen is ingesteld, is niet actueel',
		});

	if (prevPubId != null) {
		const prevPub = (await pubSrc.get({
			log,
			publicationId: prevPubId,
			recursive: false,
		}))[0] ?? throwError('Error retrieving previous publication', log);

		if (newPub.date < prevPub.date)
			warnings.push({
				fr: 'La date PRÉCÈDE celle de la publication précédente',
				nl: 'De datum GAAT VOOR die van de vorige publicatie',
			});
	}

	if (filter.allItems.find(v => v.hasErrors()) != null)
		warnings.push({
			fr: 'Il reste des erreurs',
			nl: 'Er zijn nog steeds fouten',
		});

	return await confirm({
		title: {
			fr: 'Définir comme actuel?',
			nl: 'Instellen als actueel',
		},
		content: <ul>
			{warnings.map((v) => <li key={generateId()}>{translate(v)}</li>)}
		</ul>,
	});
}

export default Publication;
